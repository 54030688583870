import React, {useState} from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionContext, useAccordionToggle, Card, Button } from "react-bootstrap";
import PanelContent from "./PanelContent";
import Configurator from "../Configurator";

const DomainPanel = ({
  brandName,
  baseUrl,
  domains,
  domainType,
  showNotification,
  initOpen,
  data,
  refreshData,
  isNew,
  primaryOffer,
}) => {

  // Accordion handling
  // eslint-disable-next-line react/prop-types
  function CustomToggle({eventKey, callback}) {
    const currentEventKey = React.useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <button
        type="button"
        className={isCurrentEventKey ? "toggle expanded" : "toggle collapsed"}
        onClick={decoratedOnClick}
      > </button>
    );
  }


  // Working domain
  const [workingDomain, setWorkingDomain] = useState("");

  // Actions
  const onPendingDomain = (domain) => {
    setWorkingDomain(domain);
  }

  return (
    <Accordion defaultActiveKey={initOpen && "0"}>
      <Card>
        <Card.Header className="row">
          <div className="col-12 padding-right-only">
            <Accordion.Toggle
              as={Button}
              className="accordion-toggle"
              variant="link"
              eventKey="0"
            >
              <span className="title">{isNew && "**NEW** "}{domainType} Domains</span>
              {brandName && " - " + brandName + ".com"}
            </Accordion.Toggle>

            <Configurator
              data={data}
              withBrandName={brandName}
              refreshData={refreshData}
              showNotification={showNotification}
              withDomainType={domainType}
              addDomainTo={baseUrl}
              showPendingDomain={onPendingDomain}
              isNew={isNew}
              rootDomain={brandName + ".com"}
              primaryOffer={primaryOffer}
              domains={domains}
            />

            <CustomToggle eventKey="0" />
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <PanelContent
              domains={domains}
              domainType={domainType}
              withBrandName={brandName}
              refreshData={refreshData}
              data={data}
              showPendingDomain={workingDomain}
              showNotification={showNotification}
              isNew={isNew}
              primaryOffer={primaryOffer}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

DomainPanel.propTypes = {
  brandName: PropTypes.string,
  baseUrl: PropTypes.string,
  domains: PropTypes.array,
  domainType: PropTypes.string,
  showNotification: PropTypes.func,
  initOpen: PropTypes.bool,
  refreshData: PropTypes.func,
  data: PropTypes.object,
  isNew: PropTypes.bool,
  primaryOffer: PropTypes.string
};

export default DomainPanel;
