
// Model offer list data
export const modelOfferListData = (data) => {
  if (Object.keys(data).length) {
    const offerData = (data).filter(function (obj) {
      return obj.customDomainPrefix !== undefined && obj.customDomainPrefix.toLowerCase() !== "n/a";
    });
    offerData.sort((a, b) => (a.offer.toLowerCase() > b.offer.toLowerCase() ? 1 : -1));
    return offerData;
  }else{
    return {};
  }
};

// Model offer list data
export const modelExDomainData = (configData, brandData, collate = false) => {
  if(collate){
    const exclusive = [];
    //const brandNames = [...new Set((data.result).map((o) => o.brandName))];
    brandData.forEach(function (brand) {
      const brandName = brand.brandName;
      const isNew = brand.isNew;
      const primaryOffer = brand.primaryOffer;
      if(brandName.toLowerCase() !== "default"){
        const domainData = [];
        (configData.result).forEach(function (obj) {
          if (brandName === obj.brandName && obj.exclusiveDomain !== undefined) {
            domainData.push({
              brandName: obj.brandName,
              pKey: obj.pKey,
              affiliateId: obj.affiliateId,
              offerId: obj.offerId,
              exclusiveDomain: obj.exclusiveDomain,
              pixelConfigs: obj.pixelConfigs,
            });
          }
        });
        domainData.sort((a, b) => (a.exclusiveDomain.toLowerCase() > b.exclusiveDomain.toLowerCase() ? 1 : -1));
        exclusive.push({
          name: brandName,
          url: brandName + ".com",
          domains: domainData,
          isNew: isNew,
          primaryOffer: primaryOffer,
        });
      }
    });
    return {
      collated: exclusive,
      native: configData.result
    };
  }else{
    return configData.result;
  }
};

// Model consolidated domain runnable data for shared domains and exclusive prefixes
export const modelRunnableOfferData = (runData, listData) => {
  const offerData = [];
  (listData).forEach(function (obj1) {
    (runData).forEach(function (obj2) {
      if (parseInt(obj1.everflowOfferId) === parseInt(obj2.networkOfferId)) {
        if(obj1.customDomainPrefix !== undefined && obj1.customDomainPrefix.toLowerCase() !== "n/a"){
          obj2.customDomainPrefix = obj1.customDomainPrefix;
          obj2.sharedUrl = obj1.offer;
          obj2.offer = obj1.offer;
          obj2.displayName = "(" + obj2.networkOfferId + ") " + obj2.name;
          offerData.push(obj2);
        }
      }
    });
  });
  offerData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  return offerData;
};

// Model shared offer list with pixel config settings
export const modelSharedOfferData = (sharedData, configData) => {
  const offerData = [];
  (sharedData).forEach(function (obj1) {
    obj1.offerId = obj1.everflowOfferId;
    obj1.exclusiveDomain = obj1.offerUrl;
    (configData).forEach(function (obj2) {
      if (parseInt(obj1.everflowOfferId) === parseInt(obj2.offerId)) {
        if(obj2.brandName.toLowerCase() === "default"){
          obj1.affiliateId = obj2.affiliateId;
          obj1.brandName = obj2.brandName;
          obj1.pKey = obj2.pKey;
          obj1.pixelConfigs = obj2.pixelConfigs;
        }
      }
    });
    offerData.push(obj1);
  });
  offerData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  return offerData;
};
