/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { UserContext } from "../../../context/user";
import { postNewDomain, postNewPrimaryOffer } from "../../../apis/updateData";
import { getZoneIdForDomain, getDomainFunctionsWarmedUp, checkDomainV3 } from "../../../apis/mp-configs";
import { poll } from "../../../common/helpers"
import v3Client from "../../../apis/v3Client";
import { queries } from "@testing-library/react";

const PrimaryOffer = ({
                          data,
                          domainName,
                          withBrandName,
                          closeModal,
                          waitIndicator,
                          primaryOffer,
                          domains,
                      }) => {

    // Location token
    let { token } = useParams();

    // Encrypted token
    const { state: user } = React.useContext(UserContext);
    const encryptedToken = token || user.token || (new URLSearchParams(window.location.search)).get('efo_mp');

    const [offerPrefix, setOfferPrefix] = useState("");

    const [currentPrimaryOffer, setCurrentPrimaryOffer] = useState("");
    const [primaryOfferString, setPrimaryOfferString] = useState("");

    const [selectedDisplayName, setSelectedDisplayName] = useState(""); // Add state for display name
    const handleSelectOffer = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        setCurrentPrimaryOffer(selectedOption.value);
        setOfferPrefix(selectedOption.value);
        setSelectedDisplayName(event.target.options[event.target.selectedIndex].text);
    };

    // API Response
    const [responseCode, setResponseCode] = useState(0);

    // Process step
    const [thisStep, setThisStep] = useState("create");

    // Build offer dropdown list
    const defaultValue = primaryOffer ? primaryOffer + "-" : ""
    const SelectOfferOptions = () => {
        const [selectedOffer, setSelectedOffer] = useState(currentPrimaryOffer);
        const Content = [];
        (data.offerPrefixes).map((domain, index) => {
            // Check if any object in the `domains` array has a matching `offerId`
            const isMatch = domains.some(d => d.offerId === domain.networkOfferId);

            if (isMatch) {
                Content.push(
                    <option
                        key={index}
                        value={domain.customDomainPrefix}
                        name={domain.displayName}
                    >
                        {domain.displayName}
                    </option>
                );
            }
        });

        return (
            <Form.Control
                id="offer-select"
                name="offer-select"
                as="select"
                custom={true}
                onChange={handleSelectOffer}
                value={currentPrimaryOffer || defaultValue || ""}
                defaultValue={defaultValue}
            >
                <option value="" data-url-prefix="">Select an Offer</option>
                {Content}
            </Form.Control>
        );
    };

    // Content display
    const DisplayHeader = (step) => {
        switch (step) {
            case "create":
                return (
                    <div className="modal-title h4">{primaryOffer ? "Update" : "Set"} Primary Offer for {domainName}</div>
                )
            case "processing":
                return null;
            case "success":
                return (
                    <div className="modal-title success h4"><i className="fa fa-check"></i>Primary Offer Set</div>
                );
            case "failure":
                return (
                    <div className="modal-title error h4">
                        <i className="fa fa-exclamation-triangle"></i>
                        An error occurred.
                    </div>
                );
        }
    }

    const DisplayContent = (step) => {
        switch (step) {
            case "create":
                return (
                    <>
                        <p className="info">
                            Select the default Offer you&apos;d like to display when shoppers
                            visit <strong>{domainName}</strong> directly.
                        </p>
                        <Form>
                            <SelectOfferOptions />
                        </Form>
                    </>
                )
            case "processing":
                return null;
            case "success":
                return (
                    <div className="description">
                        <p>Success! Primary offer for <strong>{domainName}</strong> has been set to {selectedDisplayName}. Please wait a few minutes for the change to take effect.</p>
                    </div>
                );
            case "failure":
                return (
                    <div className="description">
                        Please go back and try again.
                    </div>
                );
        }
    }
    const DisplayFooter = (step) => {
        switch (step) {
            case "create":
                return (
                    <>
                        <Button
                            variant="link"
                            onClick={closeModal}
                            className="hidden"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="action"
                            disabled={offerPrefix === ''}
                            onClick={onCreate}
                        >
                            {primaryOffer ? "Update Primary Offer" : "Set Primary Offer"}
                        </Button>
                    </>
                );
            case "processing":
                return null;
            case "success":
                return null;
            case "failure":
                return (
                    <Button
                        variant="action"
                        onClick={onReset}
                    >
                        Back
                    </Button>
                );
        }
    }

    // Actions
    const onSelectOffer = (e) => {
        if (e.target.value !== "") {
            setOfferPrefix(e.target.value);
            const dataset = e.target.options[e.target.selectedIndex].dataset;
        } else {
            setOfferPrefix('');
        }
    }

    const onReset = () => {
        setResponseCode(0);
        setThisStep("create");
    }
    const onCreate = async () => {
        if (offerPrefix === "") return;

        console.log("offerPrefix", offerPrefix);


        // compose payload
        // addDomainTo is the "core domain" being used.
        const payload = {
            domain_name: domainName,
            primary_offer: offerPrefix,
        };

        // register domain
        waitIndicator("Setting Primary Offer.");
        const data = await postNewPrimaryOffer(encryptedToken, payload);

        if (data && data.error) {
            // failed with response
            waitIndicator("");
            setResponseCode(data.error);
            setThisStep("failure");
        } else {
            if (data && data.success) {
                // success
                waitIndicator("");
                setThisStep("success");
            } else {
                // failed without response
                waitIndicator("");
                setThisStep("failure");
            }
        }
    }

    // Effects
    useEffect(() => {
        setOfferPrefix("");
        setThisStep("create");
    }, []);

    return (
        <>
            <div className="modal-header custom">
                {DisplayHeader(thisStep)}
            </div>
            <div className="content custom">
                {DisplayContent(thisStep)}
            </div>
            <div className="modal-footer custom">
                {DisplayFooter(thisStep)}
            </div>
        </>
    );
};

PrimaryOffer.propTypes = {
    data: PropTypes.object,
    withBrandName: PropTypes.string,
    domainName: PropTypes.string,
    closeModal: PropTypes.func,
    waitIndicator: PropTypes.func,
    primaryOffer: PropTypes.string,
    domains: PropTypes.array,
};

export default PrimaryOffer;