import React from "react";
import PropTypes from "prop-types";
import Configurator from "../Configurator"

// eslint-disable-next-line no-unused-vars
const PanelContent = ({
  domains = [],
  domainType,
  withBrandName,
  showNotification,
  showPendingDomain,
  data,
  refreshData,
  isNew,
  primaryOffer
}) => {
  return (
    <div className="accordion-content">

      {showPendingDomain &&
        <div className="row">
          <div className="col-12 domain-listing pending">
            <span className="domain-name vertically-centered"><i className="fa fa-clock"></i> &nbsp; {showPendingDomain} </span>
          </div>
        </div>
      }

      {!showPendingDomain && withBrandName && domains.length === 0 && domainType === "exclusive" &&
        <div className="row">
          <div className="col-12 domain-listing no-domains">
            <span className="domain-name vertically-centered"> No exclusive domains for this brand.</span>
          </div>
        </div>
      }

      {domains.map((domain, index) => (
        <div className="row" key={index}>
          <div className="col-12 domain-listing">
            <span className="domain-name vertically-centered">{domain.exclusiveDomain}</span>
            <Configurator
              withDomain={domain.exclusiveDomain}
              withDomainType={domainType}
              withDomainData={domain}
              data={data}
              refreshData={refreshData}
              showNotification={showNotification}
              isNew={isNew}
              primaryOffer={primaryOffer}
            />

          </div>
        </div>
      ))}
      {!withBrandName && domainType === "exclusive" &&
        <div>
          <p>
            Exclusive Domains have not yet been activated for your account! Please contact your GiddyUp Account
            Manager to receive access.
          </p>
          <p>
            Exclusive Domains are required to run Facebook traffic, accurately fire pixels, and verify your domain.
          </p>
          <p>
            After you’ve received access, you’ll be able to find, create, and configure all of your Exclusive Domains
            here.
          </p>
        </div>
      }
    </div>
  );
};

PanelContent.propTypes = {
  domains: PropTypes.array,
  domainType: PropTypes.string,
  withBrandName: PropTypes.string,
  showNotification: PropTypes.func,
  showPendingDomain: PropTypes.string,
  refreshData: PropTypes.func,
  data: PropTypes.object,
  isNew: PropTypes.bool,
  primaryOffer: PropTypes.string
};

export default PanelContent;
